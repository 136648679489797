import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": true,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "neonPurple",
  "types": ["drone"],
  "status": ["completed"],
  "title": "Drone Space",
  "subtitle": "Autonomous drone with computer vision",
  "description": "",
  "years": ["2017"],
  "institutions": ["dtu"],
  "location": "cph",
  "keywords": {
    "topics": ["drone"],
    "tools": ["openframeworks", "c/c++", "drone"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "developer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* # Drone Space */
    }
    <h2>{`Description`}</h2>
    <p>{`Autonomous flight simulation and planning in C/C++ in OpenFrameworks.
Autonomous Drone Flying.
Prototyped a flight simulation and control program to send flight instructions, in order to fly through a series hoops located with computer vision and sensors. And in real time show a 3D rendering of the drone, information and environment.`}</p>
    <h2>{`Media`}</h2>
    <ul>
      <li parentName="ul">{`Video`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      